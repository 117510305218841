.Container {
  height: 45px;
  width: 100%;
  border: dashed 1px #d4d4d4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.CopyButton {
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Content {
  display: flex;
  vertical-align: middle;
  flex-grow: 1;

  padding: 0 16px;

  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
} /*remove the resize handle on the bottom right*/
