@import '../../../../common/styles/main';

.Notification {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  :hover {
    .NotificationIndicator,
    .NotificationIndicatorSmall {
      transform: scale(1.1);
    }
  }
}

.NotificationIndicator {
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 50%;
  width: 40px;
  height: 40px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transition: 0.3s transform;
}

.NotificationIndicatorSmall {
  width: 40px !important;
  height: 40px !important;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transition: 0.3s transform;
}

@media (min-width: 768px) {
  .NotificationIndicator {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 1199.98px) {
  .NotificationIndicator {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
}

.NotificationInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
}

.NotificationMessage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0;
  padding: 0 16px 0 0 !important;

  > * {
    margin-left: 16px;
  }

  a {
    color: $journiDark;
  }

  a:visited {
    color: $journiDark;
  }

  a:hover,
  a:focus {
    color: $journiGreen !important;
    text-underline: none;
    text-decoration: none;
  }
}

.NotificationMessageContent {
  display: flex;
  flex-direction: column;

  time {
    // From now time...
    min-width: 60px;
    margin-bottom: 4px;
    color: darken($journiGray, 40%);
  }
}

@media (min-width: 768px) {
  .NotificationMessageContent {
    flex-direction: row;
    time {
      // From now time...
      min-width: 160px;
    }
  }
}

.NotificationMessageContentSmall {
  flex-direction: column;
}

.NotificationPhotoContainer {
  display: flex;
  width: 40px;
  height: 40px;
  position: relative;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    vertical-align: middle;
    line-height: normal;
    font-size: 36px;
    color: $lightGray;
  }
}

.NotificationPhotoContainerSmall {
  width: 40px !important;
  height: 40px !important;

  span {
    font-size: 36px !important;
  }
}

@media (min-width: 768px) {
  .NotificationPhotoContainer {
    width: 60px;
    height: 60px;

    span {
      font-size: 48px;
    }
  }
}

@media (min-width: 1199.98px) {
  .NotificationPhotoContainer {
    width: 80px;
    height: 80px;

    span {
      font-size: 64px;
    }
  }
}

.NotificationPhoto {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.NotificationPhotoSmall {
  width: 40px !important;
  height: 40px !important;
}

@media (min-width: 768px) {
  .NotificationPhoto {
    border-radius: 4px;
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 1199.98px) {
  .NotificationPhoto {
    border-radius: 4px;
    width: 80px;
    height: 80px;
  }
}

.NotificationIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: -30%;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}

.NotificationIconSmall {
  width: 20px !important;
  height: 20px !important;
}

@media (min-width: 768px) {
  .NotificationIcon {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1199.98px) {
  .NotificationIcon {
    width: 40px;
    height: 40px;
  }
}

.NotificationRequest {
  padding-left: 48px;
  > button {
    margin-left: 8px;
    margin-bottom: 2px;
  }
}

.NotificationRequestSmall {
  padding-left: 48px !important;
  > button {
    margin-left: 8px !important;
    margin-bottom: 2px !important;
  }
}

@media (min-width: 768px) {
  .NotificationRequest {
    padding-left: 80px;
  }
}

@media (min-width: 1199.98px) {
  .NotificationRequest {
    padding-left: 96px;
  }
}

.GroupedElements {
  display: flex;
  margin: 4px 8px 4px 60px;
}

.GroupedElementsSmall {
  margin: 4px 8px 4px 60px !important;
}

@media (min-width: 768px) {
  .GroupedElements {
    margin: 4px 8px 4px 108px;
  }
}

@media (min-width: 1199.98px) {
  .GroupedElements {
    margin: 4px 8px 4px 132px;
  }
}

.GroupEntriesOthers {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $journiDark;
  background-color: $journiGray;
  border-radius: 50%;
}

.GroupedUsers {
  display: flex;

  div {
    margin-right: 12px;
  }
}

.GroupedEntries {
  display: flex;

  div {
    margin-right: 12px;
  }
}

.IsNewBadge {
  position: absolute;
  left: -10px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: $melon;
}
