@import '../../../../styles/main';

.GroupUserImage {
  height: 36px;
  width: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $journiGray;
  overflow-y: hidden;
  border-radius: 8px;
  color: $journiDark;

  img {
    width: 100%;
  }
}

.GroupUserImageSmall {
  height: 36px !important;
  width: 36px !important;
}

@media (min-width: 768px) {
  .GroupUserImage {
    height: 48px;
    width: 48px;
  }
}

@media (min-width: 1199.98px) {
  .GroupUserImage {
    height: 48px;
    width: 48px;
  }
}
