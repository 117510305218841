@import '../common/styles/variables';

.mainTheme {
  background: $white;
  min-height: 100%;
  overflow: unset;
}

:global {
  a {
    color: $white;
    text-decoration: none;
  }

  /* visited link */
  a:visited {
    color: darken($white, 10%);
  }

  /* mouse over link */
  a:hover {
    text-decoration: underline;
  }

  .tPlaceholder {
    a {
      color: #8a8a94;
      text-decoration: none;
    }

    /* visited link */
    a:visited {
      color: darken(#8a8a94, 10%);
    }

    /* mouse over link */
    a:hover {
      text-decoration: underline;
    }
  }

  .darkBackground.tLabel {
    color: $white;
  }

  hr {
    background-color: $journiGray;
    height: 1px !important;
    border: 0;
  }

  // Typography colors

  h1 {
    color: $black;
  }

  h2 {
    color: $black;
  }

  body {
    color: #000000;
  }

  .tCaption {
    color: #999999;
  }

  .tCaptionDark {
    color: #666666;
  }

  .tPlaceholder {
    color: #8a8a94;
  }

  .tLabel {
    color: $greyish-brown;
  }
}
