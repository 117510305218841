@import '../../../common/styles/main';

.CardFooter {
  width: 100%;
  height: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  transition: all 1s;
}

@media (min-width: 768px) {
  .CardFooter {
    height: 40px;
  }
}

.FeaturedJourniCardFooter {
  height: 70px;
  align-items: center;
  text-align: center;

  .Title {
    margin: 0 0 4px 0;
    color: white;
  }

  .Caption {
    color: white;
  }
}

@media (min-width: 768px) {
  .FeaturedJourniCardFooter {
    height: 70px;
  }
}

.CategoryCardFooter {
  position: absolute;
  bottom: 0;
  align-items: center;
  height: 36px;

  .Title {
    color: white;
  }
}

@media (min-width: 768px) {
  .CategoryCardFooter {
    height: 56px;
  }
}

.JourniCardFooter {
  padding: 0 8px;
  height: 88px;
  justify-content: space-around;

  .Title {
    margin: 6px 0 0 0;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .Footer {
    color: white;
  }
}

@media (min-width: 768px) {
  .JourniCardFooter {
    height: 102px;
  }
}
