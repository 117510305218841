@import '../../../common/styles/main';

.Container {
  display: flex;
  border-radius: 8px;
  align-items: flex-end;
  flex-grow: 1;
  overflow: hidden; // Avoids showing Information card's rectangular borders
  position: relative;
  flex-direction: column;

  /* Fixes overflow on rounded borders when scale transformation is applied */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    .Overlay {
      transform: scale(1.15);
    }
  }
}

.Overlay {
  display: flex;
  width: 100%;
  flex-grow: 1;
  top: 0;
  left: 0;
  text-decoration: none;
  z-index: 1;
  transition: all 0.5s;
}

.FeaturedJourniCard {
  height: 218px;
}

.HasFixedWidth.FeaturedJourniCard {
  width: 283px;
}

@media (min-width: 768px) {
  .FeaturedJourniCard {
    height: 251px;
  }
}

@media (min-width: 1199.98px) {
  .FeaturedJourniCard {
    height: 300px;
  }
}

.CategoryCard {
  height: 160px;
  position: relative;
}

.HasFixedWidth.CategoryCard {
  width: 160px;
}

@media (min-width: 768px) {
  .CategoryCard {
    height: 240px;
  }

  .HasFixedWidth.CategoryCard {
    width: 240px;
  }
}

@media (min-width: 1199.98px) {
  .CategoryCard {
    height: 240px;
  }
}

.JourniCard {
  height: 318px;
}

.HasFixedWidth.JourniCard {
  width: 192px;
}

@media (min-width: 768px) {
  .JourniCard {
    height: 318px;
  }
}

@media (min-width: 1199.98px) {
  .JourniCard {
    height: 318px;
  }
}

.Wrapper {
  a:hover {
    text-decoration: none;
  }
}

.NoZooming {
  &:hover,
  &:focus {
    .Overlay {
      transform: none !important;
    }
  }
}
