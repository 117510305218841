@import '../../../common/styles/main';

.NotificationsPage {
  background: white;
  min-height: 100vh;
  display: flex;
  padding-bottom: 50px;
}

.Title {
  margin: 0;
}

.Notifications {
  display: flex;
  flex-direction: column;
}
