.HorizontalWrapper {
  div:first-child {
    padding-left: 0;
  }

  & > * {
    padding-left: 4px;
    padding-right: 4px;
  }

  div:last-child {
    padding-right: 0;
  }
}

.VerticalWrapper {
  div:first-child > div {
    padding-top: 0;
  }

  & > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  div:last-child > div {
    padding-bottom: 0;
  }
}
