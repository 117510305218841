@import '../../styles/variables';
@import '../../styles/mixins';

.Input {
  position: relative;
  width: 100%;
  height: $inputFieldHeight;
  display: block;

  &:focus {
    outline-width: 0;
    border-color: $journiGreen;
  }
}

.Borders {
  border: 1px solid $journiGray;
  border-radius: 4px;
}

.Inline {
  width: auto;
  display: inline-block;
}

.BlockInput {
  padding: 2px 16px 2px 16px !important;
}

.InputField {
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 2px 4px 2px 16px;
  border: 0 solid $journiGray;

  &:focus {
    outline-width: 0;
    border-color: $journiGreen;
  }
}

.InputFieldWithIcon {
  padding: 2px 50px 2px 16px;
}

.TextAreaContainer {
  min-height: 100px;
}

.TextArea {
  resize: none;
  padding: 8px;
}

.Label {
  text-align: left;
  display: block;
  margin-bottom: 8px;
}

.LabelInline {
  @extend .Label;
  display: inline-block;
}

.ValidationMessageContainer {
  text-align: right;
}

.Icon {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50px;
  font-size: 24px;
  line-height: 100%; // To center the icon within
  z-index: 0; // Fix for allowing 3rd-party plugins to add links or icons over the input
  color: $greyish-brown;

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

span {
  vertical-align: middle;
  line-height: normal;
}

// Invalid state

.Invalid {
  .ValidationMessageContainer {
    color: $pink-red;
  }

  .Input {
    border-color: $pink-red;
  }

  .Icon {
    color: $pink-red;
  }
}

// Valid state

.Valid {
  .ValidationMessageContainer {
    color: $journiGreen;
  }

  .Input {
    border-color: $journiGreen;
  }

  .Icon {
    color: $journiGreen;
  }
}
