@import '../../../common/styles/main';

.Oval {
  @extend .CenteredAlignIcon;
  width: 35px;
  height: 35px;
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:active {
    color: $journiGreen;
    text-decoration: none;
    cursor: pointer;
  }

  font-size: 1.25rem;
}

.CenteredAlignIcon {
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span:before {
    line-height: inherit;
  }
}
