.Followers {
  > div {
    padding: 14px 0;
    width: 100%;
  }

  > :first-child {
    padding-top: 0;
  }

  > :last-child {
    padding-bottom: 0;
  }
}
