html,
body,
#web-app-container {
  height: 100%;
  min-height: 100%;
}

/*
    Avoids border-width and padding values being added to the width of a div.
    Note: It was not included in normalize.css, so it had be added manually.
*/
html {
  box-sizing: border-box;
  overflow: scroll;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.hidden {
  visibility: hidden !important;
}

.noScrollable {
  position: fixed;
  width: 100%;
  height: 101vh;
}
