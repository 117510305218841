@import '../../../common/styles/main';

.Container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .Container {
    width: 688px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .Container {
    width: 1036px;
    margin-left: auto;
    margin-right: auto;
  }
}

.WithHorizontalPadding {
  padding: 0 16px;
}

.WithVerticalMargin {
  margin-top: 8px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .WithVerticalMargin {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1200px) {
  .WithVerticalMargin {
    margin-top: 63px;
    margin-bottom: 63px;
  }
}

.FullWidthContainer {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.FluidToRight {
  padding: 8px 0 8px 8px;
  width: 100%;
}

@media (min-width: 768px) {
  .FluidToRight {
    padding: 24px 0 24px calc(50% - 344px);
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .FluidToRight {
    padding: 63px 0 63px calc(50% - 520px);
    width: 100%;
  }
}
