@import '../../../common/styles/main';

.Overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
  z-index: 1;
  background-color: rgba(white, 0.8);
  opacity: 0;
  transition: 0.3s opacity ease-out;

  span {
    display: none;
    height: 100%;
    align-items: center;
    justify-content: center;

    &:before {
      font-size: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $journiDark;
    }
  }
}

.OverlayHover {
  &:hover {
    opacity: 1;
    cursor: pointer;

    span {
      display: flex;
    }
  }
}

.VisibleOverlay {
  opacity: 1 !important;
  background-color: rgba(black, 0.8);
  span {
    display: flex;
    height: 100%;
    animation-play-state: running !important;
    &:before {
      color: white !important;
    }
  }
}

.LoadingIcon {
  margin: auto;
  width: 100px;
  height: 100%;
}

.UploadIconTouch {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  height: 100%;

  span {
    background-color: rgba($journiDark, 0.9);
    border-radius: 100%;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 8px;

    &:before {
      font-size: 16px;
      color: $white;
    }
  }
}
