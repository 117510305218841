@import '../../../../common/styles/variables';

.Wrapper {
  height: 76px;
  border-radius: 8px;
  box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.16);

  display: flex;
  flex-grow: 1;
  width: 100%;

  padding: 8px;
  background-color: rgba(white, 0.9);

  align-items: center;
}

.Picture {
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 4px;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.Date {
  margin-bottom: 1px;
}

.Text {
  margin-top: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
