@import '../../../common/styles/main';

.IndexPage {
  background: $journiLightGray;
  min-height: 100%;
}

.NotFoundContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}

.NotFoundContent {
  color: $journiDark;
  text-align: center;
}
