@import '../../../../styles/main';

.MoreIndicator {
  height: 36px;
  width: 36px;
  background-color: $journiGray;
  overflow-y: hidden;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.MoreIndicatorSmall {
  height: 36px !important;
  width: 36px !important;
}

@media (min-width: 768px) {
  .MoreIndicator {
    height: 48px;
    width: 48px;
  }
}

@media (min-width: 1199.98px) {
  .MoreIndicator {
    height: 48px;
    width: 48px;
  }
}
