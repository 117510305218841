@import '../../styles/main';

.Box {
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  color: $journiDark;
  padding: 16px 8px;
}

.Information {
  border-color: darken($journiDark, 10%);
  color: $journiDark;
}

.Success {
  border-color: lighten($journiGreen, 10%);
  background-color: lighten($journiGreen, 5%);
  color: $white;
}

.Warning {
  border-color: lighten($butterscotch, 50%);
  background-color: lighten($butterscotch, 10%);
  color: $white;
}

.Failure {
  border-color: lighten($pink-red, 50%);
  background-color: lighten($pink-red, 10%);
  color: $white;
}
