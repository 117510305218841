.Container {
  box-sizing: border-box;
}

.Horizontal {
  div:first-child {
    padding-left: 0;
  }

  > div {
    padding-left: 4px;
    padding-right: 4px;
  }

  div:last-child {
    padding-right: 0;
  }
}

.Vertical {
  div:first-child {
    padding-top: 0;
  }

  > div {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  div:last-child {
    padding-bottom: 0;
  }
}
