@import '../../../../common/styles/main';

.Container {
  display: flex;

  a {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &:hover {
    text-decoration: none;

    .FollowerPicture {
      transform: scale(1.1);
    }
  }

  a,
  a:visited,
  a:hover {
    color: $journiDark;
    text-decoration: none;
  }
}

.FollowerPicture {
  display: flex;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  transition: 0.3s transform;
}

.FollowerDescription {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-content: center;
  justify-content: space-evenly;
  margin-left: 16px;
}

.FollowStatus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
