@import '../../../common/styles/main';

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  color: $journiGray;

  a,
  a:visited,
  a:hover,
  a:active {
    color: $journiDark;
    padding: 4px 16px;
  }

  padding-bottom: 60px;
}

@media (min-width: 576px) {
  .Container {
    flex-direction: row;
  }
}

.Brand {
  display: flex;
  padding: 4px 0;
}

.Navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
