@import '../../../styles/variables';

.Bar {
  width: 100vw;
  height: $bottomNavigationBarHeight;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: white;

  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1200px) {
  .Bar {
    display: none;
  }
}

.Content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  > div {
    margin: 0 16px;
    transition: 0.3s;
  }

  > :first-child {
    margin-left: 16px;
  }

  > :last-child {
    margin-right: 16px;
  }
}

.IconItem {
  position: relative;
  display: flex;
  align-items: center;
  color: $lightGray;

  a {
    display: flex;
    align-items: center;
    color: $lightGray;

    &:active {
      color: $journiGreen;
      span {
        transform: scale(1.2);
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $lightGray;
    }
  }

  span {
    transition: 0.3s;
    margin-right: 6px;
    display: flex;

    &:before {
      font-size: 24px;
      line-height: 100%; // To center the icon within
    }
  }
}

.ActiveIcon {
  a {
    color: $journiGreen !important;
    span {
      transform: scale(1.2);
    }
  }
}

.IsNewBadge {
  position: absolute;
  left: -12px;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: $melon;
}
