@import '../../common/styles/main';

.SignPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: $journiGreen;
}

.Logo {
  height: 48px;
  width: 202px;
  margin-top: 48px;
  margin-bottom: 56px;
}

.Bottom {
  width: 100%;
  max-width: 300px;
}

@media (min-width: 768px) {
  .Bottom {
    max-width: 620px;
  }
}

// Invitation elements

.ProfilePicture {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 50%;
}

.Invitation {
  color: $white !important;
  text-align: center;
}
