.Weather {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.WeatherImage {
  height: 85px;
}

.WeatherTemperature {
  font-size: 24px;
}
