$iconSize: 16px;

.ToTalReactions {
  display: flex;
  align-items: center;
  position: relative;
}

.IconOne {
  width: $iconSize;
  height: $iconSize;
  z-index: 1;
}

.IconTwo {
  position: absolute;
  left: 12px;
  width: $iconSize;
  height: $iconSize;
}

.TotalCount {
  padding: 0 18px;
}
