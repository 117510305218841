@import '../../../common/styles/main';

.loadingPage {
  position: fixed;
  min-height: 100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: -1;
  transition: all 1s ease-in-out, background 2s ease-in;
}

.visible {
  background-color: rgba($white, 0.9);
  opacity: 1;
  z-index: 9998;
}
