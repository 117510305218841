@import '../../../common/styles/main';

.Container {
  display: flex;
  flex-direction: column;
}

.InputField {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 2px 3px 2px 4px;
  border: 1px solid $journiGray;
  min-height: 36px;
  overflow: visible;
}
