@import '../../../common/styles/main';

.Container {
  height: 35px;
  position: relative;
  z-index: 3;
  display: flex;
  flex-shrink: 1;

  &:focus-within {
    div {
      visibility: visible;
    }
  }
}

@media (min-width: 768px) {
  .Container {
    min-width: 578px;
    width: 100%;
    height: 46px;
    visibility: visible;
  }
}

@media (min-width: 1199.98px) {
  .Container {
    width: 316px;
    height: 46px;
    visibility: visible;
  }
}

.Input {
  position: relative;
  display: block;
  width: 0;

  &:focus {
    outline-width: 0;
  }
}

.Inline {
  width: auto;
  display: inline-block;
}

.InputField {
  border-radius: 25px;
  text-indent: 4px;
  height: 100%;
  display: inline-block;

  transition: 0.3s;

  width: 0;
  padding: 0;
  border: 0;
  margin: 0;

  &::placeholder {
    color: $veryLightPink;
  }

  &:focus {
    outline-width: 0;
    border-color: $journiGreen;
  }
}

.Extended.InputField {
  width: 100%;
  padding: 2px 48px 2px 48px;
  border: 2px solid $journiGray;

  &:focus {
    outline-width: 0;
    border-color: $journiGreen;
  }
}

@media (min-width: 768px) {
  .InputField {
    width: 100%;
    padding: 2px 50px 2px 16px;
    border: 2px solid $journiGray;
  }

  .Extended.InputField {
    padding: 2px 50px 2px 16px;
  }
}

@media (min-width: 1199.98px) {
  .InputField {
    width: 100%;
    padding: 2px 50px 2px 16px;
    border: 2px solid $journiGray;
  }

  .Extended.InputField {
    padding: 2px 50px 2px 16px;
  }
}

.Icon {
  position: absolute;
  height: 100%;
  width: 50px;
  font-size: 24px;
  line-height: 100%; // To center the icon within
  color: $lightGray;

  &:hover {
    color: $lightGray;
  }

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.AmplifierIcon {
  right: 0;
}

.Extended.AmplifierIcon {
  left: 0;
}

@media (min-width: 768px) {
  .Extended.AmplifierIcon {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1199.98px) {
  .Extended.AmplifierIcon {
    left: auto;
    right: 0;
  }
}

.ClearSearchIcon {
  display: none;
  right: 0;
}

.Extended.ClearSearchIcon {
  display: flex;
}

@media (min-width: 768px) {
  .Extended.ClearSearchIcon {
    display: none;
  }
}

@media (min-width: 1199.98px) {
  .Extended.ClearSearchIcon {
    display: none;
  }
}

span {
  vertical-align: middle;
  line-height: normal;
}

.SearchBarResults {
  display: flex;
  position: fixed;
  left: 0;
  top: 45px;
  height: calc(100% - 45px);
  width: 100vw;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch; // Adds smooth scroll in IOS Safari
}

@media (min-width: 768px) {
  .SearchBarResults {
    position: fixed;
    left: 0;
    margin: 0;
    max-height: 100vh;
    width: 100vw;
    top: 55px;
  }
}

@media (min-width: 1024px) {
  .SearchBarResults {
    position: absolute;
    margin: 0;
    max-height: 600px;
    height: auto;
    width: 100%;
    border-radius: 25px;
    top: 48px;
    -webkit-overflow-scrolling: touch; // Adds smooth scroll in IOS Safari
  }
}

.SearchBarEmpty {
  visibility: hidden !important;
  div {
    visibility: hidden !important;
  }
}
