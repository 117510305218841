@import '../../../common/styles/main';

.Container {
  display: flex;
  flex-direction: column;
  min-height: 50px;

  ul {
    margin: 0;
  }
}

.OptionsContainer {
  display: flex;
  justify-content: space-evenly;

  padding: 8px;
  border: 1px solid $journiGray;
  border-radius: 25px;
}

.OptionContainer {
  display: flex;
  flex-grow: 1;
  list-style-type: none;
  padding: 0;
  position: relative;
  border-radius: 100px;
  color: $journiDark;
  min-height: 36px;

  :hover {
    cursor: pointer;
  }

  input {
    border-radius: 100px;
    width: 100%;
    height: 100%;
  }

  input:focus {
    border: 0;
    font-weight: 700;
    outline: none;
    transition: 0.3s background-color;

    &:focus {
      background-color: lighten($journiGreen, 5%);
    }
  }

  input:checked {
    background-color: $journiGreen;
  }

  input:checked ~ label {
    color: white !important;
  }

  label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.Option {
  float: left;
  margin: 0;
  position: relative;
  border: 0;
}

.Option input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Label {
  text-align: left;
  display: block;
  margin-bottom: 8px;
}

.LabelInline {
  @extend .Label;
  display: inline-block;
}
