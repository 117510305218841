/*
  Typography and Text styles
  Note that all typography classes start with t and they are globally scoped.
*/

@import './variables.scss';

@font-face {
  font-family: 'MuseoSansRounded';
  src: url('../../assets/fonts/journi/museo-sans-rounded-700.eot');
  src: url('../../assets/fonts/journi/museo-sans-rounded-700.eot')
      format('embedded-opentype'),
    url('../../assets/fonts/journi/museo-sans-rounded-700.woff') format('woff'),
    url('../../assets/fonts/journi/museo-sans-rounded-700.ttf')
      format('truetype'),
    url('../../assets/fonts/journi/museo-sans-rounded-700.svg#museo-sans-rounded-700')
      format('svg');
  font-weight: 700;
}

@font-face {
  font-family: 'MuseoSansRounded';
  src: url('../../assets/fonts/journi/museo-sans-rounded-500.otf')
      format('opentype'),
    url('../../assets/fonts/journi/museo-sans-rounded-500.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'MuseoSansRounded';
  src: url('../../assets/fonts/journi/museo-sans-rounded-300.otf')
      format('opentype'),
    url('../../assets/fonts/journi/museo-sans-rounded-300.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'journi-app';
  src: url('../../assets/fonts/journi/journi-app.eot');
  src: url('../../assets/fonts/journi/journi-app.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/journi/journi-app.woff') format('woff'),
    url('../../assets/fonts/journi/journi-app.ttf') format('truetype'),
    url('../../assets/fonts/journi/journi-app.svg#journi') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: MuseoSansRounded;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

h1 {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: normal;
  color: $black;
}

h2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  color: $black;
}

body {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #000000;
}

.tButton {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
}

.tCaption {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
}

.tCaptionDark {
  @extend .tCaption;
  color: #666666;
}

.tPlaceholder {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
}

.tLabel {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: normal;
}

.tSearchTitle {
  font-size: 15px;
  font-weight: bold;
}

@media (min-width: 768px) {
  h1 {
    font-size: 34px;
    font-weight: 700;
    letter-spacing: normal;
  }

  h2 {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: normal;
  }

  body {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }

  .tCaption {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
  }

  .tPlaceholder {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .tButton {
    font-size: 14px;
    font-weight: 500;
  }

  .tLabel {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
  }

  .tSearchTitle {
    font-size: 15px;
    font-weight: bold;
  }
}
