@import '../../../styles/variables';

.DropdownMenu {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: absolute;

  top: 150%;
  right: 0;

  width: 146px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);

  background-color: $journiGreen;
  color: white;
  z-index: 5;

  div {
    width: 100%;
    padding: 17px 0;
    border-bottom: 1px solid rgba(white, 0.37);
  }

  > :last-child div {
    border-bottom: 0;
  }
}

.OpenUpwards {
  position: absolute;
  top: auto;
  right: auto;
  left: -300%;
  bottom: 150%;
}

.Item {
  position: relative;
  color: white !important;
  &:hover {
    color: white !important;
    text-decoration: underline;
  }
}

.EmphasizedItem {
  font-weight: 700 !important;
}

.NavigationDropdown {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 5;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 6px;
  }

  &:hover {
    color: $journiGreen !important;
  }

  span {
    margin-right: 6px;
    &:before {
      font-size: 24px;
      line-height: 100%; // To center the icon within
      z-index: -1; // Fix for allowing 3rd-party plugins to add links or icons over the input
    }
  }
}

.ProfilePicture {
  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 6px;
  }
}

.SmallProfilePicture {
  img {
    width: 26px;
    height: 26px;
  }
}

.BorderedProfilePicture {
  img {
    transition: 1s transform;
    border: 2px solid $journiGreen;
    transform: scale(1.1);
  }
}

.SmallProfilePicture .BorderedProfilePicture {
  img {
    transform: scale(1.2) !important;
  }
}
