@import '../../../common/styles/main';

.Container {
  width: 100%;
  padding: 8px 13px;
  background-color: white;
  border-radius: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  > * {
    padding: 16px 0;
    border-bottom: 4px solid $journiGray;
    -webkit-tap-highlight-color: transparent;
  }

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    background: rgba($journiGreen, 0.05);
    img {
      transform: scale(1.1);
    }
  }
}

@media (min-width: 768px) {
  .Container {
    padding: 19px 13px;
    border-radius: 0;
  }
}

@media (min-width: 992px) {
  .Container {
    width: 100%;
    min-height: 0;
    border-radius: 8px;
  }
}

.CategorySectionItems {
  > * {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $journiGray;
    padding: 8px 0;
  }

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.UserSectionItems {
  > * {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $journiGray;
    padding: 4px 0;
  }

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.Item {
  display: flex;
  width: 100%;
  align-items: center;
  transition: opacity 0.1s ease-in-out, background 0.2s ease-in;
  background-color: transparent;
  position: relative;

  .Category {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .User {
    margin: 8px 0;
    display: flex;
    align-items: center;
  }
}

.ItemTitle {
  display: inline;
  font-weight: 300;
  color: $lightGray;
}

.ItemPicture {
  display: flex;
  width: 50px;
  height: 50px;
  margin-right: 16px;

  img {
    border-radius: 8px;
    transition: transform 0.5s;
    width: 50px;
    height: 50px;
  }
}

.EmphasizedText {
  font-weight: bold;
  display: inline;
  vertical-align: baseline;
  color: $black;
}

.UserTrips {
  display: block;
}
