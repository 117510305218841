.Header {
  position: relative;
  height: calc(186px);
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .Header {
    height: calc(370px);
    margin-bottom: 64px;
  }
}

@media (min-width: 1366px) {
  .Header {
    height: calc(443px);
  }
}
