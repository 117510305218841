@import '../../styles/main';

.Controls {
  visibility: hidden;
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: space-between;
  padding: 8px 16px;
}

@media (min-width: 576px) {
  .Controls {
    visibility: visible;
  }
}

.Zooming {
  display: flex;
  > * {
    margin: 0 8px;
  }
}

.Popup {
  visibility: hidden;
  display: flex;
  position: absolute;
  bottom: 0;
  padding: 8px 8px 36px 8px;
  width: 80%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .Popup {
    visibility: visible;
  }
}
