@import '../../../styles/main';

.DropdownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 150%;
  right: 0;
  width: 360px;
  height: 500px;

  border-radius: 8px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);
  background-color: $journiLightGray;
  color: $journiDark;
  z-index: 5;
}

.DropdownMenuBody {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: calc(100% - 30px);
  padding: 16px;
  overflow-y: auto;

  > :last-child {
    border-bottom: 0;
  }

  div {
    padding: 2px 0;
  }

  > a:hover {
    cursor: pointer;
    color: $journiGreen !important;
  }
}

.DropdownMenuFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $journiLightGray;
  height: 30px;
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}

.Item {
  position: relative;
  color: white !important;
  &:hover {
    color: white !important;
    text-decoration: underline;
  }
}

.NavigationDropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.FixedBottomSection {
  background-color: white;
  width: 100%;
  height: 30px;
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  left: 0;
}

.IsNewBadge {
  position: absolute;
  left: -8px;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: $melon;
}
