.UserProfilePicture {
  position: absolute;
  left: 50%;
  top: calc(100% - 30px);
  transform: translate(-50%, -50%);

  height: 137px;
  width: 137px;
  border-radius: 50%;

  border: solid 4px white;
  z-index: 4; // On top of profile cover picture;
}

@media (min-width: 768px) {
  .UserProfilePicture {
    height: 206px;
    width: 206px;
  }
}

@media (min-width: 1366px) {
  .UserProfilePicture {
    height: 181px;
    width: 181px;
  }
}
