// Colors
$polaroidBackgroundColor: #93b3f6;
$journiGreen: #00a4aa;
$journiGreenHover: #009999;
$journiGreenDark: #1badb3;
$journiBlue: #3399ff;
$journiDark: #3c4646;
$journiGray: #ededed;
$journiLightGray: #fafafa;
$facebookBlue: #3b5998;
$facebookBlueHover: #476bb6;
$twitterBlue: #4099ff;
$twitterBlueHover: lighten($twitterBlue, 5%);
$disabledText: #999999;
$disabledBackground: #ededed;
$journiDarkHover: #4e4e4e;
$googleRed: #f9534c;
$subtitleColor: #8a8a94;

$carolina-blue: #8eb3f3;
$white: #ffffff;
$denim-blue: #3b5998;
$melon: #fc8458;
$pink-red: #f60051;
$purpleish: #a6549b;
$deep-pink: #c00a66;
$butterscotch: #ffad40;
$greyish-brown: #4f4f4f;
$black: #333333;
$veryLightPink: #dadada;
$lightGray: #666666;
$yellow: #ffd334;
$violet: #b86cf0;

$chive: #abd470;
$papaya: #fc8458;

// Buttons
$buttonShortHeight: 32px;
$buttonShortMinWidth: 100px;
$buttonMinHeight: 44px;
$buttonSmallWidth: 120px;
$buttonMediumSmallWidth: 196px;
$buttonMediumWidth: 240px;
$buttonLargeWidth: 260px;
$buttonBorderRadius: 100px;

// Inputs
$inputFieldHeight: 50px;

// Navigation Bar
$navigationBarHeightSM: 45px;
$navigationBarHeightMD: 55px;
$navigationBarHeightLG: 80px;
$bottomNavigationBarHeight: 48px;

// Avatar
$avatarSuperLargeSM: 128px;
$avatarSuperLargeMD: 256px;
