@import '../../../common/styles/main';

.Container {
  border-radius: 100%;
  position: relative;
}

.SuperLarge {
  width: $avatarSuperLargeSM;
  height: $avatarSuperLargeSM;
}

@media (min-width: 576px) {
  .SuperLarge {
    width: $avatarSuperLargeMD;
    height: $avatarSuperLargeMD;
  }
}

.Large {
  width: 137px;
  height: 137px;
}

.Medium {
  width: 64px;
  height: 64px;
}

.Small {
  width: 26px;
  height: 26px;
}

.WithBorder {
  border: 6px solid rgba(white, 0.8);
}

.WithInnerBorder {
  padding: 2px;
  border: 2px solid rgba(white, 0.8);
}

.Author {
  @extend .Medium;
  @extend .WithInnerBorder;
}

.Overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
  z-index: 1;
  background-color: rgba(white, 0.2);
  opacity: 0;
  transition: 0.3s opacity ease-out;
  border-radius: 100%;

  &:hover {
    opacity: 1;
  }
}
