@import '../../styles/main';

.Background {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.5);
  z-index: 9999;
}

.Container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .Container {
    width: 500px;
    height: 400px;
    border-radius: 8px;
  }
}

.Header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 8px;
  padding: 8px;
  border-bottom: 1px solid $journiGray;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .Header {
    flex: none;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 16px 26px;
}

.CloseButton {
  position: relative;
}

@media (min-width: 1024px) {
  .CloseButton {
    position: fixed;
    right: 30px;
    top: 30px;
  }
}

@media (min-width: 1024px) {
  .Followers {
    width: 490px;
    height: 640px;
  }
}

@media (min-width: 1024px) {
  .Stamps {
    width: 900px;
    height: 500px;
  }
}

.Footer {
  display: flex;
  height: 48px;
  align-content: center;
  justify-content: center;
  margin: 16px;
}
