@import '../../../common/styles/main';

.Label {
  text-align: left;
  display: block;
  font-weight: 700 !important;
}

.LabelInline {
  @extend .Label;
  display: inline-block;
}

.Container {
  display: flex;
  flex-direction: column;
}

.Content {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  transition: 0.5s transform;

  input[type='checkbox']:focus {
    outline: none;
    transform: scale(1.1);
  }
}

.Checkbox {
  position: relative;
  display: flex;
  flex-shrink: 0;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid $journiDark;
  border-radius: 100%;
  margin-right: 16px;
  background-color: white;

  &:hover {
    cursor: pointer;
  }
}

.Checked {
  border: 2px solid $journiGreen;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: $journiGreen;
    border-radius: 100%;
  }
}

.DetailedLabel {
  display: flex;
  flex-direction: column;
  margin-left: 36px; // Checkbox width + margin

  span {
    padding-top: 4px;
  }
}
