@import '../../common/styles/main';

.User {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  h1 {
    margin: 0;
  }

  > div {
    width: 100%;
  }
}

.UserName {
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  margin: auto;
  word-break: break-word;
}

@media (min-width: 768px) {
  .UserName {
    max-width: 340px;
  }
}

.PremiumBadge {
  display: flex;
  justify-content: center;
}

.Description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  a,
  a:visited,
  a:hover {
    color: $journiDark;
  }
}

.Badges {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: 4px 0;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .Badges {
    display: flex;
    flex-direction: row;
    justify-content: center;

    > * {
      margin: 0 4px;
    }

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: 1366px) {
  .Badges {
    display: flex;
    flex-direction: row;
    justify-content: center;

    > * {
      margin: 0 4px;
    }

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }
}

.BadgesButton {
  width: 227px;
}

@media (min-width: 768px) {
  .BadgesButton {
    width: 169px;
  }
}

@media (min-width: 1366px) {
  .BadgesButton {
    width: 169px;
  }
}

.ProfileDropdownButton {
  display: flex;
  justify-content: center;
}

.FollowButton {
  display: flex;
  justify-content: center;
}

.Journis {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}

.JournisTitle {
  :hover {
    text-decoration: none;
  }
}

.JournisTabItem {
  display: inline-block;
  margin-right: 16px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.JournisActiveTabItem {
  padding-bottom: 8px;
  border: 0;
  border-bottom: 4px solid $journiGreen;
  border-radius: 1.8px;
  background-color: unset;
}
