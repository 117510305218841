@import '../../../common/styles/main';

.Label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: 700 !important;
}

.LabelInline {
  @extend .Label;
  display: inline-block;
}

.Container {
  display: flex;
  flex-direction: column;
}

.Content {
  margin-bottom: 8px;
  transition: 0.5s transform;
}

.Switch {
  position: relative;
  flex-shrink: 0;
  margin-right: 16px;

  &:hover {
    cursor: pointer;
  }
}

.DetailedLabel {
  text-align: left;
  margin: 0 0 0 56px;
  padding: 0;
  list-style: none;

  li {
    padding-top: 4px;
  }
}
