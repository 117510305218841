@import '../../common/styles/main';

.SignPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: $journiGreen;
}

.EmailNotifications {
  span {
    text-align: left;
  }

  h3 {
    text-align: left;
  }
}

.Logo {
  height: 48px;
  width: 202px;
  margin-top: 48px;
  margin-bottom: 56px;
}

.Bottom {
  width: 100%;
  max-width: 300px;
}

@media (min-width: 768px) {
  .Bottom {
    max-width: 620px;
  }
}

.highlight {
  background-color: lighten($butterscotch, 32%);
  border-radius: 8px;
}

.Validation {
  display: flex;
  flex-grow: 1;
}
