/*
  Journi Font Icon Classes. Globally scoped.
 */
[data-icon]:before {
  font-family: 'journi-app' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'journi-app' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-worth-seeing-streamline:before {
  content: '\61';
}

.icon-accommodation-streamline:before {
  content: '\62';
}

.icon-nightlife-streamline:before {
  content: '\63';
}

.icon-shopping-streamline:before {
  content: '\64';
}

.icon-culture-art-streamline:before {
  content: '\65';
}

.icon-transport-streamline:before {
  content: '\66';
}

.icon-coffee-drinks-streamline:before {
  content: '\67';
}

.icon-food-streamline:before {
  content: '\68';
}

.icon-activity-streamline:before {
  content: '\69';
}

.icon-nature-streamline:before {
  content: '\6a';
}

.icon-entertainment-streamline:before {
  content: '\6b';
}

.icon-architecture-streamline:before {
  content: '\6c';
}

.icon-relax-streamline:before {
  content: '\6d';
}

.icon-other-streamline:before {
  content: '\6e';
}

.icon-scale-down:before {
  content: '\6f';
}

.icon-scale-up:before {
  content: '\70';
}

.icon-notifications:before {
  content: '\71';
}

.icon-notifications-streamline:before {
  content: '\72';
}

.icon-camera:before {
  content: '\73';
}

.icon-camera-streamline:before {
  content: '\74';
}

.icon-gps:before {
  content: '\75';
}

.icon-gps-streamline:before {
  content: '\76';
}

.icon-delete:before {
  content: '\77';
}

.icon-delete-streamline:before {
  content: '\78';
}

.icon-bus-streamline:before {
  content: '\79';
}

.icon-scooter-streamline:before {
  content: '\7a';
}

.icon-journis:before {
  content: '\41';
}

.icon-journis-streamline:before {
  content: '\42';
}

.icon-heart:before {
  content: '\43';
}

.icon-heart-streamline:before {
  content: '\44';
}

.icon-lock:before {
  content: '\45';
}

.icon-lock-streamline:before {
  content: '\46';
}

.icon-mail:before {
  content: '\47';
}

.icon-mail-streamline:before {
  content: '\48';
}

.icon-shop-streamline:before {
  content: '\4a';
}

.icon-pin:before {
  content: '\4b';
}

.icon-pin-streamline:before {
  content: '\4c';
}

.icon-shop:before {
  content: '\4d';
}

.icon-user:before {
  content: '\4e';
}

.icon-more:before {
  content: '\4f';
}

.icon-pen-streamline:before {
  content: '\53';
}

.icon-shuffle:before {
  content: '\54';
}

.icon-rounded-minus:before {
  content: '\56';
}

.icon-share-streamline:before {
  content: '\57';
}

.icon-download:before {
  content: '\58';
}

.icon-settings:before {
  content: '\59';
}

.icon-settings-streamline:before {
  content: '\5a';
}

.icon-pig:before {
  content: '\30';
}

.icon-pig-streamline:before {
  content: '\31';
}

.icon-star:before {
  content: '\32';
}

.icon-star-streamline:before {
  content: '\33';
}

.icon-search-active:before {
  content: '\35';
}

.icon-contacts:before {
  content: '\36';
}

.icon-facebook:before {
  content: '\37';
}

.icon-pinterest:before {
  content: '\38';
}

.icon-twitter:before {
  content: '\39';
}

.icon-instagram:before {
  content: '\21';
}

.icon-rounded-plus:before {
  content: '\3e';
}

.icon-ios:before {
  content: '\51';
}

.icon-android:before {
  content: '\52';
}

.icon-collapse-arrow:before {
  content: '\5f';
}

.icon-rounded-ckeck:before {
  content: '\3b';
}

.icon-chevron:before {
  content: '\2b';
}

.icon-chevron-down:before {
  content: '\5b';
}

.icon-chevron-left:before {
  content: '\2a';
}

.icon-chevron-up:before {
  content: '\5d';
}

.icon-tag-streamline:before {
  content: '\2d';
}

.icon-nowifi:before {
  content: '\60';
}

.icon-book:before {
  content: '\5e';
}

.icon-journi:before {
  content: '\26';
}

.icon-aa-center:before {
  content: '\22';
}

.icon-crop:before {
  content: '\23';
}

.icon-tilt-left:before {
  content: '\24';
}

.icon-tilt-right:before {
  content: '\25';
}

.icon-comment-outline:before {
  content: '\27';
}

.icon-polaroid:before {
  content: '\28';
}

.icon-ship-standard:before {
  content: '\29';
}

.icon-calendar-portrait:before {
  content: '\2c';
}

.icon-calendar-landscape:before {
  content: '\2e';
}

.icon-calendar-square:before {
  content: '\2f';
}

.icon-mouse:before {
  content: '\3c';
}

.icon-mouse-streamline:before {
  content: '\3d';
}

.icon-rounded-plus-1:before {
  content: '\e000';
}

.icon-rounded-x:before {
  content: '\e001';
}

.icon-up-down:before {
  content: '\e002';
}

.icon-clock:before {
  content: '\e004';
}

.icon-calendar:before {
  content: '\e005';
}

.icon-book-landscape-small:before {
  content: '\e008';
}

.icon-book-landscape-1:before {
  content: '\e009';
}

.icon-plane:before {
  content: '\e00a';
}

.icon-cloudy:before {
  content: '\e00b';
}

.icon-notfound:before {
  content: '\e00d';
}

.icon-cloudy-streamline:before {
  content: '\e00f';
}

.icon-rain:before {
  content: '\e010';
}

.icon-rain-streamline:before {
  content: '\e011';
}

.icon-snow:before {
  content: '\e012';
}

.icon-snow-streamline:before {
  content: '\e013';
}

.icon-sun:before {
  content: '\e014';
}

.icon-sun-streamline:before {
  content: '\e015';
}

.icon-storm:before {
  content: '\e016';
}

.icon-storm-streamline:before {
  content: '\e017';
}

.icon-cloud:before {
  content: '\e018';
}

.icon-cloud-streamline:before {
  content: '\e019';
}

.icon-info:before {
  content: '\e01a';
}

.icon-dropbox:before {
  content: '\e01b';
}

.icon-details:before {
  content: '\e01c';
}

.icon-book-portrait:before {
  content: '\e01d';
}

.icon-book-square:before {
  content: '\e01e';
}

.icon-align-center:before {
  content: '\e01f';
}

.icon-text:before {
  content: '\e020';
}

.icon-warning:before {
  content: '\e021';
}

.icon-align-justified:before {
  content: '\e022';
}

.icon-comment:before {
  content: '\e023';
}

.icon-align-left:before {
  content: '\e024';
}

.icon-align-right:before {
  content: '\e025';
}

.icon-rocket:before {
  content: '\e026';
}

.icon-truck:before {
  content: '\e027';
}

.icon-credit-card:before {
  content: '\e028';
}

.icon-hand-swipe:before {
  content: '\e029';
}

.icon-phone-turn:before {
  content: '\e02a';
}

.icon-locator:before {
  content: '\e02b';
}

.icon-photos:before {
  content: '\e02c';
}

.icon-share-android:before {
  content: '\e02e';
}

.icon-search:before {
  content: '\e02f';
}

.icon-tag:before {
  content: '\e030';
}

.icon-hand-swipe-left:before {
  content: '\e031';
}

.icon-present:before {
  content: '\e032';
}

.icon-eye-show:before {
  content: '\e033';
}

.icon-eye-hide:before {
  content: '\e034';
}

.icon-facebook-icon:before {
  content: '\e036';
}

.icon-instagram-icon:before {
  content: '\e037';
}

.icon-add-photos:before {
  content: '\e038';
}

.icon-book-streamline:before {
  content: '\e039';
}

.icon-add-stickers:before {
  content: '\e03b';
}

.icon-gift-card:before {
  content: '\e040';
}

.icon-shopping-bag:before {
  content: '\e03e';
}

.icon-rounded-ckeck-only:before {
  content: '\49';
}

.icon-alert:before {
  content: '\50';
}

.icon-bell:before {
  content: '\55';
}

.icon-bell-ring:before {
  content: '\3a';
}

.icon-layer-streamline:before {
  content: '\3f';
}

.icon-phone:before {
  content: '\40';
}

.icon-help:before {
  content: '\34';
}

.icon-google-photos:before {
  content: '\7b';
}
.icon-rounded-close:before {
  content: '\7c';
}

.icon-twitter-icon:before {
  content: '\7d';
}

.centered-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
