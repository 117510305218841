@import '../../../styles/variables';

.PublicNavigationBar {
  height: $navigationBarHeightSM;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-bottom: 2px solid rgba(151, 151, 151, 0.26);

  position: fixed;
  width: 100%;
  z-index: 999;
}

@media (min-width: 768px) {
  .PublicNavigationBar {
    height: $navigationBarHeightMD;
  }
}

@media (min-width: 1200px) {
  .PublicNavigationBar {
    height: $navigationBarHeightLG;
  }
}

/* Navigation bar is absolutely positioned. Additional space is needed to avoid overlapping */
.PublicNavigationBarLayout {
  height: 45px;
}

@media (min-width: 768px) {
  .PublicNavigationBarLayout {
    height: 55px;
  }
}

@media (min-width: 1200px) {
  .PublicNavigationBarLayout {
    height: 80px;
  }
}

@media (min-width: 768px) {
  .PublicNavigationBar {
    flex-grow: 1;
  }
}

@media (min-width: 1200px) {
  .PublicNavigationBar {
    flex-grow: 0;
  }
}

.Content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .Content {
    width: 688px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .Content {
    width: 1036px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Links {
  display: flex;
  justify-content: space-around;

  > div {
    transition: 0.3s;
  }

  > :first-child {
    margin-left: 4px;
  }

  > :last-child {
    margin-right: 4px;
  }
}

@media (min-width: 768px) {
  .Links {
    > div {
      margin: 0 16px;
      transition: 0.3s;
    }

    > :first-child {
      margin-left: 16px;
    }

    > :last-child {
      margin-right: 16px;
    }
  }
}

@media (min-width: 1200px) {
  .Links {
    > div {
      margin: 0 16px;
      transition: 0.3s;
    }

    > :first-child {
      margin-left: 16px;
    }

    > :last-child {
      margin-right: 16px;
    }
  }
}

.IconItem {
  color: $lightGray;
  align-items: center;
  display: flex;

  &:hover {
    color: $journiGreen !important;
  }

  a {
    display: flex;
    align-items: center;
    color: $lightGray;

    &:hover {
      text-decoration: none;
      color: $journiGreen !important;
      cursor: pointer;
    }

    &:visited {
      color: $lightGray;
    }
  }

  span {
    margin-right: 6px;
    &:before {
      font-size: 24px;
      line-height: 100%; // To center the icon within
      z-index: -1; // Fix for allowing 3rd-party plugins to add links or icons over the input
    }
  }
}

@media (min-width: 1200px) {
  .IconItem {
    display: flex;
    flex-shrink: 0;
  }
}

.HideOnMobileView {
  display: none;
}

@media (min-width: 1200px) {
  .HideOnMobileView {
    display: flex;
  }
}

.JourniLogo {
  width: 30px;
  height: 30px;
  transition: 0.5ms;
  transform: rotate(0deg);
  align-items: center;
  display: flex;
  justify-content: left;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  &:hover {
    animation-play-state: running !important;
  }
}

.JourniLogo.HiddenLogo {
  display: none;
}

@media (min-width: 768px) {
  .JourniLogo {
    width: 45px;
    height: 45px;
  }

  .JourniLogo.HiddenLogo {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .JourniLogo {
    width: 60px;
    height: 60px;
  }

  .JourniLogo.HiddenLogo {
    display: flex;
  }
}

.PublicBottomNavigationBar {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 6;
}

@media (min-width: 768px) {
  .PublicBottomNavigationBar {
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .PublicBottomNavigationBar {
    display: none;
  }
}
