@import '../../common/styles/global';

.Entries {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Placeholder {
  height: 80vh;
}

@media (min-width: 576px) {
  .Entries {
    margin-right: 16px;
  }
}

$mapVerticalOffset: 24px; // Margin property is not suitable

.Map {
  display: none; // Map is only visible when the media query is satisfied
  width: 100%;
  height: calc(
    100vh - #{$navigationBarHeightSM} - #{$bottomNavigationBarHeight} - 2 *#{$mapVerticalOffset}
  );

  position: -webkit-sticky;
  position: sticky;
  top: $navigationBarHeightSM + $mapVerticalOffset;

  transition: transform 1s ease-in-out;
  transform: translateY(-240px);

  border-radius: 8px;
  border: 2px transparent;

  overflow: hidden;
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .Map {
    height: calc(
      100vh - #{$navigationBarHeightMD} - #{$bottomNavigationBarHeight} - 2 *#{$mapVerticalOffset}
    );
    top: calc(#{$navigationBarHeightMD} + #{$mapVerticalOffset});
    display: flex;
    margin-left: 16px;
  }
}

@media (min-width: 1200px) {
  .Map {
    height: calc(100vh - #{$navigationBarHeightLG} - 2 *#{$mapVerticalOffset});
    top: calc(#{$navigationBarHeightLG} + #{$mapVerticalOffset});
  }
}

.TranslatedMap {
  transform: translateY(0);
}

@media (min-width: 576px) {
  .Map {
    display: flex;
    margin-left: 16px;
  }
}

.MapFullScreen {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.Wrapper {
  display: flex;
  margin: 24px 0;
}
