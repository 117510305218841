@import '../../styles/variables';

.HorizontalStrike {
  display: flex;
  align-items: center;
  text-align: center;
}

.HorizontalStrike::before,
.HorizontalStrike::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $journiGray;
}

.HorizontalStrike::before {
  margin-right: 15px;
}

.HorizontalStrike::after {
  margin-left: 15px;
}
