/*
  This mixin provides a background line that surrounds the element
  e.g.:
  ---------------- CONTENT ------------------

  $color: sets the line color
 */

@mixin bg-strike($color) {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  > span {
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 0.5px;
      background: $color;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

/* Styles in global scope are used by multiple elements. In order
to reduce each component stylesheet file, we make them global.
*/
:global {
  $sizes: 12;

  /*
    Generates margin classes from 0 to 4 rem for all directions
     m[0-12]: in all directions
     ml[0-12]: left margin
     mr[0-12]: right margin
     mt[0-12]: top margin
     mb[0-12]: bottom margin
     mx[0-12]: left and right margin
     my[0-12]: top and bottom margin
 */
  @mixin margin-classes {
    @for $i from 0 through $sizes {
      $margin: $i * 0.25rem;
      /* margin #{$margin} */
      .m#{$i} {
        margin: $margin;
      }
      .ml#{$i} {
        margin-left: $margin;
      }
      .mr#{$i} {
        margin-right: $margin;
      }
      .mt#{$i} {
        margin-top: $margin;
      }
      .mb#{$i} {
        margin-bottom: $margin;
      }
      .mx#{$i} {
        margin-left: $margin;
        margin-right: $margin;
      }
      .my#{$i} {
        margin-top: $margin;
        margin-bottom: $margin;
      }

      @media (min-width: 768px) {
        $margin: $i * 0.5rem;
        .m#{$i} {
          margin: $margin;
        }
        .ml#{$i} {
          margin-left: $margin;
        }
        .mr#{$i} {
          margin-right: $margin;
        }
        .mt#{$i} {
          margin-top: $margin;
        }
        .mb#{$i} {
          margin-bottom: $margin;
        }
        .mx#{$i} {
          margin-left: $margin;
          margin-right: $margin;
        }
        .my#{$i} {
          margin-top: $margin;
          margin-bottom: $margin;
        }
      }
    }

    /*
        Auto margin classes for all directions
     */

    .m-auto {
      margin: auto;
    }

    .ml-auto {
      margin-left: auto;
    }

    .mr-auto {
      margin-right: auto;
    }

    .mt-auto {
      margin-top: auto;
    }

    .mb-auto {
      margin-bottom: auto;
    }

    .my-auto {
      margin-top: auto;
      margin-bottom: auto;
    }

    .mx-auto {
      margin-left: auto;
      margin-right: auto;
    }
  }

  /*
    Generates padding classes from 0 to 4 rem for all directions
     p[0-12]: in all directions
     pl[0-12]: left padding
     pr[0-12]: right padding
     pt[0-12]: top padding
     pb[0-12]: bottom padding
     px[0-12]: left and right padding
     py[0-12]: top and bottom padding
 */
  @mixin padding-classes {
    @for $i from 0 through $sizes {
      $padding: $i * 0.25rem;
      /* padding #{$padding} */
      .p#{$i} {
        padding: $padding;
      }
      .pl#{$i} {
        padding-left: $padding;
      }
      .pr#{$i} {
        padding-right: $padding;
      }
      .pt#{$i} {
        padding-top: $padding;
      }
      .pb#{$i} {
        padding-bottom: $padding;
      }
      .px#{$i} {
        padding-left: $padding;
        padding-right: $padding;
      }
      .py#{$i} {
        padding-top: $padding;
        padding-bottom: $padding;
      }

      @media (min-width: 768px) {
        $margin: $i * 0.5rem;
        .p#{$i} {
          padding: $padding;
        }
        .pl#{$i} {
          padding-left: $padding;
        }
        .pr#{$i} {
          padding-right: $padding;
        }
        .pt#{$i} {
          padding-top: $padding;
        }
        .pb#{$i} {
          padding-bottom: $padding;
        }
        .px#{$i} {
          padding-left: $padding;
          padding-right: $padding;
        }
        .py#{$i} {
          padding-top: $padding;
          padding-bottom: $padding;
        }
      }
    }
  }

  // These two includes generate padding and margin classes
  @include margin-classes;
  @include padding-classes;
}

/*
  Prevent the text in an element to be selected.
*/
@mixin user-select {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
