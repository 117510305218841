@import '../../../../styles/main';

.GroupEntryImage {
  height: 36px;
  width: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $journiGray;
  overflow-y: hidden;
  border-radius: 8px;

  color: $journiDark;

  span {
    margin-right: 0;
  }

  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  img {
    width: 100%;
  }
}

.GroupEntryImageSmall {
  height: 36px !important;
  width: 36px !important;
}

@media (min-width: 768px) {
  .GroupEntryImage {
    height: 48px;
    width: 48px;
  }
}

@media (min-width: 1199.98px) {
  .GroupEntryImage {
    height: 48px;
    width: 48px;
  }
}
