.Content {
  position: relative;
  margin-top: 16px;
  display: flex;
}

@media (min-width: 1440px) {
  .Content {
    margin-top: 24px;
  }
}

.Collections {
  display: flex;
  flex-direction: row;

  > div {
    display: flex;
    flex-grow: 1;
    margin: 8px;
  }

  > div > div {
    margin-bottom: 16px;
  }
}
