.rotateClockwise {
  animation: rotate 10s linear infinite;
  transform-box: fill-box;
  transform-origin: center;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

// Credits to http://waitanimate.wstone.io
.makeItShake {
  animation: shake_3366 2.375s ease infinite;
  transform-origin: 50% 50%;
  animation-play-state: paused !important;
}

@keyframes shake_3366 {
  0% {
    transform: rotate(0deg);
  }
  8.42106% {
    transform: rotate(3deg);
  }
  10.52631% {
    transform: rotate(3deg);
  }
  18.94737% {
    transform: rotate(-6deg);
  }
  21.05263% {
    transform: rotate(-6deg);
  }
  29.47368% {
    transform: rotate(6deg);
  }
  31.57895% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-6deg);
  }
  42.10526% {
    transform: rotate(-6deg);
  }
  50.52632% {
    transform: rotate(6deg);
  }
  52.63158% {
    transform: rotate(6deg);
  }
  61.05263% {
    transform: rotate(-6deg);
  }
  63.15789% {
    transform: rotate(-6deg);
  }
  71.57895% {
    transform: rotate(6deg);
  }
  78.94737% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0 !important;
  }
  to {
    opacity: 1 !important;
  }
}

// Animations to use with CSSTransition library
// According documentation, *-active classes represent which styles you want to animate to.

.page-change-enter {
  opacity: 0.8;
}

.page-change-enter.page-change-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.page-change-leave {
  opacity: 1;
}

.page-change-leave.page-change-leave-active {
  opacity: 0.8;
  transition: opacity 300ms ease-in;
}
