@import '../../common/styles/main';

.ProfileSettings {
  margin-top: 16px;
  padding-top: 16px;
}

.Head {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
  }

  > div {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}

.SaveContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Membership {
  justify-content: space-between;
  align-items: center;
}

.EmailNotificationSection {
  flex-direction: column;
}

.Section {
  padding: 16px 0;
  border-bottom: solid 7px $journiGray;
  display: flex;
}

.LastSection {
  border-bottom: 0;
}
