@import '../../../common/styles/main';

.Container {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdditionalTrips {
  background: $journiGreen;
  color: white;
  padding: 2px;
  border: 2px solid rgba(white, 0.8);
  transition: background 0.3s ease-in-out;

  &:hover {
    background: lighten($journiGreen, 3%);
  }
}
